<template>
  <div>
    <div class="body">
      <div class="tt df aic">
        <div class="suxian"></div>
        关联订单列表
      </div>

      <div v-if="this.productList.length > 0" class="have">
        <div class="li" v-for="(item, index) in productList" :key="index">
          <div class="head df">
            <div style="width: 100%" class="content">
              <div class="bg"></div>
              <li>
                <div class="cld realpay">
                  {{ item.order.status > 110 ? "实" : "应" }}付总金额：
                  <span style="color:#E33233">¥ {{
                          item.order.status > 100
                            ? item.order.payAmount
                            : item.order.totalAmount
                        }}</span>
                </div>
                <div class="su"></div>
                <div class="cld">
                  订单号：
                  <span>{{ item.order.orderSn }}</span>
                </div>
                <div class="su"></div>

                <div class="cld">
                  下单时间：
                  <span>{{ item.order.createTime }}</span>
                </div>
                <div class="cld" style="color: #26c487; flex: 1; justify-content: flex-end">
                  <!-- 待付款状态 //订单状态：-1全部 待付款-100 支付中-110 待发货-120 部分发货-130 待收货-140 部分收货-150   已完成-160 已关闭-170-->
                  <div v-if="item.order.status == 100" class="daifukuan">
                    <span>待付款</span>
                  </div>
                  <div v-if="item.order.status == 110" class="daifukuan">
                    <span>支付中</span>
                  </div>
                  <div v-if="item.order.status == 140" class="daishouhuo">
                    待收货
                  </div>
                  <div v-if="item.order.status == 120" class="daifahuo">
                    待发货
                  </div>
                  <div v-if="item.order.status == 160" class="daishouhuo">
                    已完成
                  </div>
                  <div v-if="item.order.status == 170" class="yiguanbi">
                    已关闭
                  </div>
                </div>
              </li>
              <li>
                <div class="cld">
                  客户：
                  <span>{{ item.order.customerName }}</span>
                </div>
                <div class="su"></div>
                <div class="cld">
                  收货方式：
                  <span>
                    {{
                          item.order.receiveType == 1 ? "送货上门" : "到仓自提"
                        }}
                  </span>
                </div>
              </li>
              <li class="df flex-between">
                <div class="cld">
                  <div>
                    {{
                          item.order.receiveType == 1 ? "收货人" : "提货人"
                        }}信息：
                  </div>

                  <span>
                    {{ item.order.takeName || item.order.receiveName }}
                    {{ item.order.takePhone || item.order.receivePhone }}
                    {{ item.order.receiveAddress }}
                  </span>
                </div>
              </li>
            </div>
          </div>
          <div>
            <div class="cardlist">
              <div v-for="(it, idx) in item.orderItems" :key="idx">
                <div v-if="idx < 2">
                  <div class="bigl">
                    <Product-Card :isMyorder='true' :rows="it"> </Product-Card>
                  </div>
                </div>
              </div>
              <div v-if="item.totalNum - 2 > 0" class="su"></div>
              <div v-if="item.totalNum - 2 > 0" class="listNum">
                <div class="num nwp">
                  剩余{{
                        item.totalNum - 2 > 0 ? item.totalNum - 2 : 0
                      }}类商品
                </div>
              </div>

              <div v-if="item.totalNum - 2 > 0" class="su"></div>
              <div class="listNum">
                <div v-if="item.totalNum <= 2" style="margin-right:20px" class="su"></div>
                <div style="justify-content: center" class="caozuo df">
                  <div @click="goDetail(item)" class="xiangqing gr cup">
                    查看详情
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="total > 10" class="pagenation">
        <el-pagination background @current-change="handleCurrentChange" :current-page.sync="page" :page-size="10" layout="prev, pager, next, total,jumper" :total="total"></el-pagination>
      </div>
    </div>
  </div>
</template>
 
<script>
import ProductCard from "@/components/custom-base/custom-product-card";
import { getOrderDetailByPaySn } from "@/request/Loan/Loan.js";
export default {
  components: {
    ProductCard,
  },
  data() {
    return {
      productPic: "",
      LoanInfo: [],
      productList: [],
      page: 1,
      total: 0,
      status: 0,
    };
  },
  created() {
    this.getList();
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, olVal) {
        if (newVal.params.orderNo) {
          this.LoanInfo = this.$route.params;
          localStorage.setItem("orderNo", newVal.params.orderNo);
          localStorage.setItem("loanId", newVal.params.loanId);
        } else {
          this.LoanInfo.orderNo = localStorage.getItem("orderNo");
          this.LoanInfo.loanId = localStorage.getItem("loanId");
        }
      },
    },
  },
  methods: {
    goDetail(item) {
      this.$router.push({
        name: "OrderDetail",
        params: { id: item.order.id },
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getList() {
      let data = {
        isAsc: "asc",
        pageNum: this.page,
        pageSize: 10,
        paySn: this.LoanInfo.orderNo,
      };
      getOrderDetailByPaySn(data).then((res) => {
        if (res.code === 200) {
          this.productList = res.data.rows;
          this.productList.map((item) => {
            item.orderItems.map((it) => {
              it.transImg = it.productPic.split(",");
              if (it.transImg.length !== 0) {
                let urlImg = [];
                it.transImg.map((it) => {
                  if (it.lastIndexOf("show") !== -1) {
                    urlImg.push(it);
                  }
                });
                it.productPic =
                  urlImg.length > 0 ? urlImg[0] : it.productPic.split(",");
              }
            });
          });
          this.total = res.data.total;
        }
      });
    },
  },
};
</script>
 
<style lang = "less" scoped>
@import "../../../assets/base.less";

.body {
  padding: 25px 110px 35px 30px;
  background-color: #fff;
  padding-bottom: 35px;
}
.realpay {
  font-size: 14px !important;
  font-family: DIN-Bold, DIN;
  font-weight: bold;
  color: #333 !important;
}
.daifukuan {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: rgb(249, 235, 217);
  border-radius: 2px 2px 2px 2px;
  font-weight: 400;
  color: #faa743;
}
.daifukuanhd {
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 0 20px;
  align-items: center;
  height: 42px;
  background: #f4fffb;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid rgba(38, 196, 135, 0.34);
  filter: blur(undefinedpx);
  font-size: 14px;
}
.daishouhuo {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: #d4efe7;
  border-radius: 2px 2px 2px 2px;
  color: #3ec993;
}
.daifahuo {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: #f8eadb;
  border-radius: 2px 2px 2px 2px;
  color: #f9a948;
}
.yiguanbi {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: #e9e9ed;
  border-radius: 2px 2px 2px 2px;
  color: #a2a3ae;
}
.active {
  color: @green;
  border-bottom: 4px solid @green;
}
.back {
  height: 48px;
  line-height: 48px;
  color: #707070;
}
.cup:hover {
  color: @green;
}
.li {
  margin-bottom: 20px;
  width: 100%;
  background: #fff;
  border: 1px solid @hui;
  box-sizing: border-box;
  .head {
    width: 100%;
    /* height: 120px; */
    box-sizing: border-box;
    background: #f7f8fa;
    border-bottom: 1px solid @hui;
    padding: 18px 20px 11px 17px;
    .content {
      li {
        margin-bottom: 10px;
        line-height: 20px;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: #595a6d;
        .cld {
          font-size: 12px;
          display: flex;
          color: #595a6d;
        }
        .su {
          margin: 0 14px;
          border: 1px solid #e7ebe7;
          display: inline-block;
          vertical-align: middle;
          border-left: 1px solid @hui;
          content: "";
          height: 12px;
          line-height: 12px;
        }
      }
    }
  }

  .cardlist {
    align-items: center;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px 22px;
    .su {
      margin-top: 2px;
      display: inline-block;
      vertical-align: middle;
      border-right: 1px solid @hui;
      content: "";
      height: 79px;
      padding-left: 10px;
      line-height: 79px;
    }
    .listNum {
      display: flex;
      text-align: center;
      align-items: center;
      padding: 0 6px;
      .num {
        font-size: 14px;
        color: #bbbccb;
      }
    }
    .bigl:nth-child(2n) {
      margin-left: 30px;
    }

    .price {
      border-top: 1px dashed #f5f5f5;
      padding-top: 5px;
      display: flex;
      justify-content: flex-end;
      height: 20px;
      line-height: 20px;
      .ge {
        margin-right: 60px;
        font-size: 14px;
        color: #adafad;
      }
      .total {
        margin-right: 20px;
        font-size: 14px;
        font-weight: bold;
        color: @green;
      }
    }
    .lii {
      padding-bottom: 14px;
      border-bottom: 2px dashed @hui;
      display: flex;
      min-width: 350px;
      /* width: 360px; */

      .card {
        text-align: center;
        border: 1px solid @hui;
        width: 86px;
        height: 92px;
        box-sizing: border-box;
      }
      .goodsDetail {
        flex: 1;
        margin-left: 16px;
        line-height: 1.4;
        .h1 {
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: 500;
        }
        .info {
          font-size: 12px;
          color: #999999;
        }
      }
    }
    .caozuo {
      width: 80px;
      text-align: center;
      white-space: nowrap;
      .zhifu {
        font-size: 14px;
        color: #26c487;
        margin-bottom: 20px;
      }
      .xiangqing {
        font-size: 14px;
      }
    }
  }
}

.pagenation {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.tt {
  font-size: 18px;
  font-family: 'medium';
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  padding-bottom: 30px;
  font-weight: 600;
  margin-bottom: 0;
}
</style>