<template>
  <div :style="rows.errorMsg ? 'padding-bottom:20px' : ''" class="cardlist">
    <div class="lii">
      <div class="card">
        <img :src="productPic || $store.state.Imgpic" class="img" alt />
        <div v-if="rows.loans" class="icon-box">贷</div>
        <div v-if="rows.status == 'DOWN'" class="isdown">
          <img src="@/assets/images/cart/isDwon.png" alt="" />
        </div>
        <div v-if="rows.helpFlag == '0'" class="ishelp">
          <img src="@/assets/images/cart/ishelp.png" alt="" />
        </div>

        <!-- 申请议价 -->
        <div v-if="rows.bargainingFlag == 1" class="isApply">
          <img src="@/assets/images/cart/changePrice.png" alt="" />
        </div>
        <!-- 快速下单导入 -->
        <div v-if="rows.productFromType == 1" class="toLead">
          <img
            style="width: 100%; height: 100%"
            src="@/assets/images/cart/toLead.png"
            alt=""
          />
        </div>
      </div>
      <div class="goodsDetail">
        <div class="h1">
          <div style="max-width:250px" class="name ellipsis-one">
            {{ rows.productName }}
            <span v-show="rows.isGift === 1" style="color: #FF7D00">(赠品)</span>
          </div>
          <div v-if="isMyorder && showPrice" class="price">
            {{
              waitPrice
                ? ""
                : rows.activityType == 1
                ? "¥" + rows.activityPrice
                : "¥" + rows.salesPrice
            }}
          </div>
        </div>
        <div class="info df ">
          <el-tooltip
            class="item"
            effect="dark"
            :content="rows.manufacturer"
            placement="top-start"
          >
            <div style="min-width: 36px;max-width:190px" class="ellipsis-one">
              厂家： <span>{{ rows.manufacturer }}</span>
            </div>
          </el-tooltip>
        </div>
        <div class="info">件装量： {{ rows.packSpec }}{{ rows.unit }}</div>
        <div v-if="isMyInvoice" class="info">规格： {{ rows.spec }}</div>
        <div v-if="!rows.validPeriodTime && !isMyorder" class="info">
          效期： {{ rows.validPeriod }}个月
        </div>
        <div v-if="rows.validPeriodTime && !isMyorder" class="info">
          效期至： {{ rows.validPeriodTime | dateFormat }}
        </div>
        <div v-if="rows.errorMsg && isCart" class="isRightdown">
          <div style="width: 350px" class="ellipsis-one">
            <i style="color: #ffaa34" class="el-icon-warning"></i>
            {{ rows.errorMsg }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    isMyorder: {
      type: Boolean,
      default: false,
    },
    isMyInvoice: {
      type: Boolean,
      default: false,
    },
    waitPrice: {
      type: Boolean,
      default: false,
    },
    isCart: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    rows: {
      handler(value) {
        //压缩图片
        if (value.productPic && value.productPic.lastIndexOf("show") !== -1) {
          this.productPic =
            value.productPic
              .split(",")?.[0]
              .replace(
                "show",
                "showcompress?compressType=size&value=264x264"
              ) ||
            value.productPic.replace(
              "show",
              "showcompress?compressType=size&value=264x264"
            );
        } else {
          this.productPic =
            value.productPic.split(",")?.[0] || value.productPic;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      productPic: "",
    };
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";
.cardlist {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .lii {
    display: flex;
    align-items: flex-start;
    .card {
      position: relative;
      text-align: center;
      border: 1px solid @hui;
      width: 90px;
      height: 90px;
      box-sizing: border-box;
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .isdown {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .ishelp {
        position: absolute;
        width: 100%;
        bottom: 0;
        img{
          width: 100%;
          vertical-align: bottom;
        }
      }
      .isApply {
        position: absolute;
        top: 0;
        left: 0;
      }
      .toLead {
        width: 36px;
        height: 20px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .icon-box{
        position: absolute;
        top:0;
        right:0;
        width:15px;
        height:20px;
        font-size: 12px;
        color:#ffffff;
        text-align: center;
        line-height: 20px;
        background-image: url(../../../assets/images/goods-icon.svg);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .goodsDetail {
      position: relative;
      flex: 1;
      margin: 0 12px;
      line-height: 1.5;
      color: #131313;
      text-align: left;
      .isRightdown {
        text-align: left;
        color: #fdc06d;
        position: absolute;
        left: -100px;
      }
      .h1 {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        .price {
          font-size: 14px;
          font-family: DIN-Medium, DIN;
          font-weight: bold;
          color: #26c487;
        }
      }
      .info {
        margin-bottom: 3px;
        flex: 0 0 120px;
        white-space: pre-wrap;
        font-size: 12px;
        color: #999999;
        text-align: left;
      }
    }
  }
  .lef {
    flex: 1;
  }
  .righ {
    height: 20px;
    line-height: 20px;
    justify-content: flex-end;
  }
  .fo {
    padding-bottom: 30px;
    border-bottom: 1px dashed @hui;
  }
}
</style>
